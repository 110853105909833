import { IRequestType } from 'interfaces/ApiType/apiType';

import { baseAPI as api } from './index';

const feedBackApi = async (requestObject: IRequestType) => {
  const url = '/feedback';
  const response = await api.post(url, requestObject, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Authorization:
        typeof window !== 'undefined' && localStorage.getItem('token')
          ? `Bearer ${localStorage.getItem('token')}`
          : '',
    },
  });
  return response;
};

export default feedBackApi;
