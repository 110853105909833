import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { isBrowser } from '@/constants/index';

import { queryClient, publicApi } from './index';

interface UploadFileRequest {
  file: File | (File & { previewURL: string });
  name: string;
  recaptchaToken: string;
  config?: AxiosRequestConfig;
}

export const uploadMediaAPI = async (request: UploadFileRequest) => {
  const body = new FormData();
  body.append('file', request.file);
  body.append('name', request.name);

  return publicApi.post(
    `/file/create`,
    body,
    isBrowser && localStorage.getItem('token')
      ? {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            RecaptchaToken: request.recaptchaToken,
          },
          ...request.config,
        }
      : {
          ...request.config,
          headers: {
            RecaptchaToken: request.recaptchaToken,
          },
        }
  );
};

export const useUploadMedia = () => {
  return useMutation<any, unknown, UploadFileRequest>(
    'upload-media',
    uploadMediaAPI,
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );
};
