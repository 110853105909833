import React, { useState } from 'react';

import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';

import feedBackApi from '@/api/feedBackApi';
import useMediaQuery from '@/hooks/useMediaQuery';
import { sendNotifcation } from '@/utils/notification';
import SadLogo from 'public/assets/fileverseLogoSad.svg';

import { Spinner } from '../Spinner/Spinner';

const Page500 = () => {
  const router = useRouter();
  const isMinMedia720px = useMediaQuery('(min-width : 720px)');
  const [userIssue, setUserIssue] = useState('');
  const [reportPending, setReportPending] = useState(false);
  const [feedBackDelivered, setFeedBackDelivered] = useState(false);
  const { data: signer } = useAccount();
  const handleReport = async () => {
    setReportPending(true);
    const requestBody = {
      comment: userIssue,
      url: router.asPath,
      address: signer?.address,
    };
    try {
      const response = await feedBackApi(requestBody);
      if (response.status === 200) {
        setUserIssue('');
        setReportPending(false);
        setFeedBackDelivered(true);
      }
    } catch (error) {
      setReportPending(false);
      sendNotifcation('Unable to give feedback', '', 'danger');
    }
  };
  return (
    <div
      className={`${
        isMinMedia720px ? ' w-[700px]' : 'h-full'
      } p-4 bg-white no-scrollbar overflow-auto flex flex-col justify-center items-center m-auto rounded-lg `}
    >
      {feedBackDelivered ? (
        <div className="mb-4">
          <div className="flex justify-center">
            <img src={SadLogo.src} alt="fileverse" className=" h-40 " />
          </div>
          <p className="text-2xl font-semibold text-center m-4">
            Your Feedback has been recieved by our Team.
          </p>
          <p className="text-center">
            We apologize for the error you are experiencing on this page, we now
            know about this and we will try to resolve this problem as soon as
            possible.{' '}
          </p>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => router.push('/')}
              className="bg-yellow mt-4 py-2 px-6 font-semibold rounded-md"
            >
              <span>Go Home</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          <p className="text-2xl font-semibold text-center">
            Oops! looks like you have hit the wall
          </p>
          <h1 className="font-xx text-6xl">500!</h1>
          <>
            <textarea
              onChange={(e) => setUserIssue(e.target.value)}
              value={userIssue}
              className=" w-full rounded-md h-60 p-3 mt-4"
              placeholder="What were you trying to do"
            ></textarea>
            <button
              onClick={handleReport}
              className="bg-yellow mt-4 py-2 px-6 font-semibold rounded-md"
            >
              {reportPending ? (
                <span>
                  <Spinner />
                </span>
              ) : (
                <span>Tell Us</span>
              )}
            </button>
          </>
        </>
      )}
    </div>
  );
};

export default Page500;
