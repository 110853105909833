import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';

import { loginAPI } from '@/api/login';
import HeadSection from '@/components/BodyLayout/HeadSection';
import Wrapper from '@/components/BodyLayout/Wrapper';
import Page500 from '@/components/ErrorPage/Page500';
import FileUploadZone from '@/components/FileUploadZone/FileUploadZone';
import { Spinner } from '@/components/Spinner/Spinner';
import { sendNotifcation } from '@/utils/notification';
import MainLogo from 'public/assets/mainLogo.png';
import { useEagerConnect } from 'utils/useEagerConnect';

import { isTokenValid } from '../utils';

export default function Landing() {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)');

  const { data: signer } = useAccount();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const [errorPage, setErrorPage] = useState(false);
  // const isMedia920px = useMediaQuery('(max-width : 920px)');

  const [userLogin, setUserLogin] = useState(false);

  const handleConnect = async () => {
    try {
      const token = localStorage.getItem('token');

      setUserLogin(isTokenValid(token, signer?.address));
      if (!isTokenValid(token, signer?.address) && signer?.address) {
        const message = new SiweMessage({
          domain: window.location.host,
          address: signer?.address,
          statement: `Welcome to Fileverse\nTimestamp: ${new Date().toISOString()}`,
          uri: window.location.origin,
          version: '1',
        });
        const signature = await signMessageAsync({
          message: message.prepareMessage(),
        });
        const result = await loginAPI({
          address: signer?.address,
          signature,
          message: message.prepareMessage(),
        });
        if (result) {
          const accessToken = result?.data?.token;
          localStorage.setItem('token', accessToken);
          localStorage.setItem('address', signer?.address);
          setUserLogin(true);
        }
      }
      return;
    } catch (error) {
      disconnect();
      sendNotifcation('Wallet Disconnected', '', 'danger');
    }
  };

  useEagerConnect();
  useEffect(() => {
    handleConnect();
  }, [signer?.address]);

  if (!userLogin)
    return errorPage ? (
      <Wrapper>
        <Page500 />
      </Wrapper>
    ) : (
      <div className="h-[100vh]">
        <div className={`h-[10vh] p-4 flex items-center`}>
          <HeadSection disableHeaderWalletLogin={true} />
        </div>

        <div
          className={`  h-[80vh] no-scrollbar overflow-y-auto px-4 flex-row  ${
            isMediaMax1025px ? 'flex-wrap' : 'flex'
          }`}
        >
          <div className={`flex flex-col justify-center w-full`}>
            <div
              className={`justify-center text-center flex flex-col h-[80%] ${
                isMediaMax1025px ? 'scale-[90%]' : ''
              }`}
            >
              <div>
                <div className="text-3xl font-semibold">Stay On-Chain</div>
                <div className="text-xl">
                  Share files with your wallet, your peers, your rules.
                </div>
                <ConnectButton.Custom>
                  {({ openConnectModal }) => {
                    return (
                      <div className="mt-4">
                        {(() => {
                          const connecting =
                            signer?.address &&
                            typeof window !== 'undefined' &&
                            !isTokenValid(
                              localStorage.getItem('token'),
                              signer?.address
                            );
                          return (
                            <>
                              <button
                                className="bg-black text-white rounded-lg px-14 py-4 text-xl"
                                onClick={openConnectModal}
                              >
                                {connecting ? <Spinner /> : 'Connect'}
                              </button>
                            </>
                          );
                        })()}
                      </div>
                    );
                  }}
                </ConnectButton.Custom>
              </div>
            </div>
          </div>
          <div
            className={`flex
              flex-col justify-center mr-4 ${
                isMediaMax1025px ? 'items-center w-full' : ''
              }`}
          >
            <span className="text-xl px-8 font-semibold">OR</span>
          </div>
          <div className={`w-full flex mt-2 items-center`}>
            <FileUploadZone setErrorPage={setErrorPage} />
          </div>
        </div>
        <div className="w-full  h-[10vh] pt-2 flex items-center justify-center">
          <div
            className={`flex items-center font-normal mb-4 ${
              isMediaMax1025px ? '' : 'rounded-xl'
            }  w-[34rem] px-2 bg-[#F8F8F8] h-full 	text-[#777777]`}
          >
            <img alt="fileverse" src={MainLogo.src} className="w-8 h-8" />
            <div
              className={`  ${
                isMediaMax1025px ? 'text-xs ' : 'text-sm'
              } text-[#777777] text-left pl-2`}
            >
              Fileverse is a trustless collaboration dApp supported by 14,000+
              Gitcoin & Ethereum donors as well as leading protocols, investors
              & angels 💛
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <Wrapper>
      {errorPage ? (
        <Page500 />
      ) : (
        <div className="flex flex-col justify-center items-center h-full w-full">
          <div
            className={`grid w-[45%] h-[80vh] min-h-fit pb-10 ${
              isMediaMax1025px ? 'w-[95%]' : ''
            } `}
          >
            <FileUploadZone setErrorPage={setErrorPage} authenticated={true} />
          </div>
        </div>
      )}
    </Wrapper>
  );
}
